import {
  QueryHookOptions,
  useQuery,
  MutationHookOptions,
  useMutation,
  useLazyQuery,
} from "@apollo/client";
import {
  WorkOrdersQuery,
  WorkOrdersQueryVariables,
  WorkOrdersDocument,
  WorkOrderUpdateMutation,
  WorkOrderUpdateMutationVariables,
  WorkOrderUpdateDocument,
  WorkOrderCreateMutation,
  WorkOrderCreateMutationVariables,
  WorkOrderCreateDocument,
  WorkOrderDocument,
  WorkOrderQuery,
  WorkOrderQueryVariables,
  WorkOrderStatusDocument,
  WorkOrderStatusQuery,
  InventoryStatusFilterQuery,
  InventoryStatusFilterDocument,
  WorkOrderCloneMutation,
  WorkOrderCloneMutationVariables,
  WorkOrderCloneDocument,
  WorkOrderCategoriesQuery,
  WorkOrderCategoriesDocument,
  WorkOrderStatusQueryVariables,
  WorkOrderCategoriesQueryVariables,
  InventoryStatusFilterQueryVariables,
  WorkOrderOutputsDocument,
  WorkOrderOutputsQuery,
  WorkOrderOutputsQueryVariables,
} from "../../graphql";
import { useIntl } from "react-intl";
import { formatStatus, formatStockVariantOption } from "../../formats";
import { usePermissions } from "../basic/users";
import { merge } from "lodash";

export function useWorkOrderStatusOptions() {
  const [load, { loading, data }] = useLazyQuery<
    WorkOrderStatusQuery,
    WorkOrderStatusQueryVariables
  >(WorkOrderStatusDocument);

  const options = data?.__type?.enumValues
    ?.filter((s) => s.name != "reverting" && s.name != "completing")
    ?.sort((a, b) => a.name.localeCompare(b.name))
    ?.map((s) => ({
      key: s.name,
      value: s.name,
      label: formatStatus(s.name, "badge"),
    }));

  return {
    load,
    loading,
    options,
  };
}

export function useWorkOrderTypeFormat() {
  const intl = useIntl();

  return {
    formatWorkOrderCategory: (category: string) =>
      intl.formatMessage({ id: `workOrders.categories.${category}` }),
    formatWorkOrderType: (type: string) =>
      intl.formatMessage({ id: `workOrders.types.${type}` }),
  };
}

export function useWorkOrderTypeOptions() {
  const { formatWorkOrderType, formatWorkOrderCategory } =
    useWorkOrderTypeFormat();

  const [load, { data, loading }] = useLazyQuery<
    WorkOrderCategoriesQuery,
    WorkOrderCategoriesQueryVariables
  >(WorkOrderCategoriesDocument);

  const options = data?.workOrderCategories
    ?.map((c) =>
      c.workOrderTypes.map((t) => ({
        key: t,
        label: formatWorkOrderType(t),
        group: formatWorkOrderCategory(c.name),
      }))
    )
    .reduce((acc, val) => acc.concat(val), [])
    .sort((a, b) => a.label.localeCompare(b.label));

  return {
    load,
    loading,
    options,
  };
}

export function useInventoryStatusFilterOptions() {
  const intl = useIntl();

  const [load, { loading, data }] = useLazyQuery<
    InventoryStatusFilterQuery,
    InventoryStatusFilterQueryVariables
  >(InventoryStatusFilterDocument);

  const options =
    data?.__type?.enumValues
      ?.map((s) => ({
        key: s.name,
        value: s.name,
        label: intl.formatMessage({ id: `inventoryStatuses.${s.name}` }),
      }))
      ?.sort((a, b) => a.label.localeCompare(b.label)) || [];

  return {
    load,
    loading,
    options,
  };
}

export function useWorkOrders(
  options?: QueryHookOptions<WorkOrdersQuery, WorkOrdersQueryVariables>
) {
  const { loading, data } = useQuery(WorkOrdersDocument, options);

  if (!data || !data.workOrders || !data.workOrders.items) {
    return { loading };
  }

  const items = data.workOrders.items;
  const totalCount = data.workOrders.totalCount;

  return { loading, items, totalCount };
}

export function useWorkOrderOutputOptions(
  queryOptions?: QueryHookOptions<
    WorkOrderOutputsQuery,
    WorkOrderOutputsQueryVariables
  >
) {
  const intl = useIntl();

  const [load, { loading, data }] = useLazyQuery(
    WorkOrderOutputsDocument,
    queryOptions
  );

  const search = () => {
    load();
  };

  const options = data?.workOrders?.items.flatMap((wo) =>
    wo.outputs.map((v) => ({
      key: v.id,
      label: `${v.variant.name} - WO #${wo.id} - ${intl.formatNumber(
        v.totalAmount
      )} ${v.unit.abbr}`,
      children: formatStockVariantOption(
        { ...v.variant, stock: { onHand: v.totalAmount } },
        ` / WO #${wo.id} - ${intl.formatDate(wo.documentDate)}`
      ),
      variant: v.variant,
      workOrder: wo,
      workOrderVariant: v,
    }))
  );

  return { load, loading, search, options };
}

export function useWorkOrder(id: string) {
  const showWage = usePermissions((p) => p.settings?.showWage);
  const { data, startPolling, stopPolling, refetch } = useQuery<
    WorkOrderQuery,
    WorkOrderQueryVariables
  >(WorkOrderDocument, { variables: { id, showWage } });

  return {
    workOrder: data?.workOrder,
    startPolling,
    stopPolling,
    refetch,
  };
}

export function useWorkOrderUpdate(
  options?: MutationHookOptions<
    WorkOrderUpdateMutation,
    WorkOrderUpdateMutationVariables
  >
) {
  const showWage = usePermissions((p) => p.settings?.showWage);
  const showVars = {
    variables: { showWage } as WorkOrderUpdateMutationVariables,
  };

  return useMutation(WorkOrderUpdateDocument, merge(options, showVars));
}

export function useWorkOrderCreate(
  options?: MutationHookOptions<
    WorkOrderCreateMutation,
    WorkOrderCreateMutationVariables
  >
) {
  const showWage = usePermissions((p) => p.settings?.showWage);
  const showVars = {
    variables: { showWage } as WorkOrderCreateMutationVariables,
  };

  return useMutation(WorkOrderCreateDocument, merge(options, showVars));
}

export function useWorkOrderClone(
  options?: MutationHookOptions<
    WorkOrderCloneMutation,
    WorkOrderCloneMutationVariables
  >
) {
  return useMutation(WorkOrderCloneDocument, options);
}
