import { Tooltip, TooltipProps } from "antd";
import { colors } from "../../lib/formats";

export function HelpTooltip({
  disabled,
  ...props
}: TooltipProps & { disabled?: boolean }) {
  if (disabled) return <>{props.children}</>;

  return (
    <Tooltip
      trigger={["focus"]}
      placement="right"
      color={colors.aragroColor}
      overlayStyle={{ maxWidth: "30vw", whiteSpace: "pre-line" }}
      {...props}
    />
  );
}
