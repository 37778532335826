import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { AntDatePicker } from "./DatePicker";
import { PickerTimeProps } from "antd/lib/date-picker/generatePicker";
import { useCurrentLocale } from "../../lib/hooks";

export interface TimePickerProps
  extends Omit<PickerTimeProps<Dayjs>, "picker" | "value"> {
  value?: string;
}

export const TIME_FORMAT = "h:mm a";

export const TimePicker = React.forwardRef<any, TimePickerProps>(
  ({ value, ...props }, ref) => {
    const { antLocale } = useCurrentLocale();

    return (
      <AntDatePicker
        format={TIME_FORMAT}
        minuteStep={15}
        locale={antLocale.DatePicker}
        use12Hours
        value={value ? dayjs(value).tz() : undefined}
        {...props}
        picker="time"
        mode={undefined}
        ref={ref}
      />
    );
  }
);
