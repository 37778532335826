import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import dayjs, { Dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { SwapRightOutlined } from "@ant-design/icons";
import { Space, Tooltip } from "antd";
import { ReactNode } from "react";
import { useWeekDays } from "../hooks";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

export type DateTimeType = string | number | Date;

export const WeekdaysShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export function formatDate(
  date?: DateTimeType,
  {
    timeTooltip,
    ...props
  }: Intl.DateTimeFormatOptions & { timeTooltip?: boolean } = {}
) {
  if (!date) return null;

  // force UTC timezone if date argument is date string
  if (typeof date === "string" && date.length === 10) props.timeZone = "utc";

  const result = <FormattedDate value={date} {...props} />;

  if (timeTooltip) {
    return (
      <Tooltip title={formatTime(date)}>
        <span>{result}</span>
      </Tooltip>
    );
  }
  return result;
}

export function formatTime(
  time?: DateTimeType,
  props?: Intl.DateTimeFormatOptions
) {
  if (!time) return null;

  return <FormattedTime value={time} {...props} />;
}

export function formatDateTime(date: DateTimeType) {
  return (
    <>
      {formatDate(date)} {formatTime(date)}
    </>
  );
}

export function formatDateDiff(
  start: DateTimeType,
  end: DateTimeType,
  addSign?: boolean
) {
  if (!start || !end) return null;

  if (addSign && dayjs(start).isBefore(end))
    return <span>+{formatDateDiff(start, end)}</span>;

  return formatDays(dayjs.duration(dayjs(end).diff(start)).asDays().toFixed(0));
}

export function formatDays(days: string | number) {
  return (
    <FormattedMessage
      id="age.value"
      values={{
        years: 0,
        months: 0,
        days,
      }}
    />
  );
}

export function formatDateFromNow(
  date?: DateTimeType,
  showDifference?: boolean
) {
  if (!date) return null;

  if (!showDifference) return dayjs(date).fromNow();

  const diff = dayjs.duration(Math.abs(dayjs().diff(date)));
  const years = diff.years();
  diff.subtract(years, "years");
  const months = diff.months();
  diff.subtract(months, "months");
  const days = diff.days();

  return (
    <FormattedMessage
      id="age.value"
      defaultMessage="complex format value"
      values={{ years, months, days }}
    />
  );
}

export function formatTimeRange(
  startTime?: DateTimeType,
  endTime?: DateTimeType
) {
  return (
    <>
      {formatTime(startTime)} <SwapRightOutlined />{" "}
      {!dayjs(endTime).isSame(startTime) && formatTime(endTime)}
    </>
  );
}

export function formatDateRange(
  startDate?: DateTimeType,
  endDate?: DateTimeType | null,
  props?: Intl.DateTimeFormatOptions
) {
  return (
    <>
      {formatDate(startDate, props)} <SwapRightOutlined />{" "}
      {endDate &&
        !dayjs(endDate).isSame(startDate) &&
        formatDate(endDate, props)}
    </>
  );
}

export function formatWeek(
  date: dayjs.Dayjs | DateTimeType,
  startDate: DateTimeType,
  endDate?: DateTimeType
) {
  if (endDate && dayjs(date).isAfter(endDate)) return undefined;

  const weeks = dayjs.duration(dayjs(date).diff(startDate)).asWeeks() + 1;
  if (weeks < 0) return undefined;

  return weeks.toFixed();
}

export function formatWeekSuffix(
  content: ReactNode,
  week?: number | string | null
) {
  return (
    <Space wrap>
      {content}
      <span>
        (
        <FormattedMessage
          id="weekNumber.short"
          defaultMessage="WK #{week}"
          values={{ week }}
        />
        )
      </span>
    </Space>
  );
}

export function resetTime(date: DateTimeType | Dayjs, hour = 6) {
  return dayjs
    .tz(date)
    .set("hour", hour)
    .set("minute", 0)
    .set("seconds", 0)
    .set("milliseconds", 0);
}

export function useBusinessTime(startTime?: DateTimeType) {
  const { items } = useWeekDays();

  const resetBusinessTime = function (date: Dayjs) {
    if (items) {
      const weekDay = items.find((i) => i.day == date.day());

      if (startTime || weekDay?.startTime) {
        const time = dayjs(startTime || weekDay?.startTime).tz();

        return dayjs
          .tz(date)
          .set("hour", time.hour())
          .set("minute", time.minute())
          .set("seconds", 0)
          .set("milliseconds", 0);
      }
    }

    return resetTime(date);
  };
  return { resetBusinessTime };
}

export function defaultWeekDays() {
  return WeekdaysShort.map((name) => ({
    name,
    working: name != "Sun",
    startTime: resetTime(dayjs(), 6),
    endTime: resetTime(dayjs(), 14),
  }));
}
