import { Row, Col, Menu, Typography } from "antd";
import { ReactNode, useState } from "react";
import { useMediaXs } from "../../../lib/hooks";
import { useEffectOnce } from "react-use";
import { useRouter } from "next/router";

interface MenuContentItem {
  title: ReactNode;
  header?: ReactNode;
  description?: ReactNode;
  group?: string;
  disabled?: boolean;
  render?(): ReactNode;
  items?: MenuContentItem[];
  route?: string;
}

export interface MenuContentProps {
  selectedKey?: string;
  items: Record<string, MenuContentItem>;
  children?: React.ReactNode;
  onChange?(key: string): void;
}

export function MenuContent({
  items,
  selectedKey,
  children,
  onChange,
}: MenuContentProps) {
  const isXs = useMediaXs();
  const [currentKey, setCurrentKey] = useState(
    selectedKey || Object.keys(items)[0]
  );

  const currentItem = items[currentKey] || Object.values(items)[0];

  useEffectOnce(() => {
    if (location.hash) setCurrentKey(location.hash.replace("#", ""));
  });

  const router = useRouter();

  return (
    <Row gutter={[32, 32]} style={{ marginBottom: 40 }}>
      <Col xs={24} sm={8} lg={4}>
        <Menu
          triggerSubMenuAction="click"
          mode={isXs ? "horizontal" : "inline"}
          selectedKeys={[currentKey]}
          style={{ height: "100%" }}
          onSelect={({ key }) => onChange && onChange(key)}
          items={Object.entries(items).map(([key, i]) => ({
            key,
            style: {
              fontSize: "16px",
              background: "none",
            },
            disabled: i.disabled || key === i.group,
            onClick: () => {
              if (i.route) {
                router.push(i.route);
              } else {
                setCurrentKey(key);
                location.hash = key;
              }
            },
            label: (
              <span
                style={
                  i.group && key != i.group
                    ? { paddingLeft: "16px" }
                    : undefined
                }
              >
                {i.title}
              </span>
            ),
          }))}
        />
      </Col>

      <Col xs={24} sm={16} lg={20}>
        <Typography.Title level={4}>
          {currentItem.header || currentItem.title}
        </Typography.Title>

        {currentItem.description && (
          <p>
            <Typography.Text type="secondary">
              {currentItem.description}
            </Typography.Text>
          </p>
        )}

        {children
          ? children
          : currentItem.render && !currentItem.disabled && currentItem.render()}
      </Col>
    </Row>
  );
}
