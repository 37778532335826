import { Tag, Typography } from "antd";
import { Space } from "../../components/shared";
import { MachineFragment, MachineShortFragment } from "../graphql";
import routes from "../routes";
import { formatDetailsLink } from "./common";

export function formatMachine(
  machine: MachineShortFragment & { discardedAt?: boolean }
) {
  return (
    <div>
      {formatDetailsLink({
        id: machine.id,
        route: routes.machines.details,
        title: machine.name,
        discarded: !!machine.discardedAt,
      })}
      <div>{machine.kind.name}</div>
    </div>
  );
}

export function formatMachineOption(machine: MachineFragment) {
  return (
    <Space>
      {machine.name}-
      <Typography.Text type="secondary">{machine.model}</Typography.Text>
      <Tag color="blue">{machine.brand.name}</Tag>
    </Space>
  );
}
