import { BulkOperationResult, Maybe, Scalars, UserError } from "../graphql";
import {
  MutationHookOptions,
  MutationTuple,
  useApolloClient,
  OperationVariables,
  ApolloCache,
} from "@apollo/client";
import { useCallback } from "react";
import { DocumentNode } from "graphql";
import { useMedia } from "react-use";

export const DEFAULT_PAGE_SIZE = 50;

export interface WithIDType {
  id: Scalars["ID"];
}

export interface WithConditionalIDType {
  id?: Scalars["ID"] | null;
}

export interface DiscardableType extends WithIDType {
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  mayDiscard?: boolean;
  mayDestroy?: boolean;
}

export interface LockableType extends WithIDType {
  locked: Scalars["Boolean"];
}

export type QueryVariables<FT, ST> = {
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
  filter?: Maybe<FT>;
  sort?: Maybe<ST>;
};

export interface QueryResult<T> {
  loading: boolean;
  items?: T[];
  totalCount?: Scalars["Int"];
}

export interface IDMutationVariables {
  id: Scalars["ID"];
}

export interface UpdateMutationVariables<T> extends IDMutationVariables {
  changes: T;
}

export interface BulkUpdateMutationVariables<T> {
  ids: Scalars["ID"] | Array<Scalars["ID"]>;
  changes: T;
}

export interface BulkUpdateMutationResult {
  result: BulkOperationResult;
}

export interface CreateMutationVariables<T> {
  fields: T;
}

export interface SaveMutationResult<T> {
  result?: T | null;
  errors: Array<UserError>;
}

export interface DestroyMutationVariables extends IDMutationVariables {
  dryRun?: Maybe<Scalars["Boolean"]>;
}

export type SaveFunction<T> = Promise<
  SaveMutationResult<T> | null | undefined
> | void;

export type SaveIDFunction = SaveFunction<WithIDType>;

export interface DestroyMutationResult {
  result?: Maybe<Scalars["Boolean"]>;
  errors: Array<UserError>;
}

export interface FormValues<T> {
  id?: Scalars["ID"];
  values: T;
}

export type Destroy<T> = T & { _destroy?: boolean };

export interface MutationProps<TMutation, TMutationVariables, TMutationResult> {
  mutation(
    options?: MutationHookOptions<TMutation, TMutationVariables>
  ): MutationTuple<TMutation, TMutationVariables>;
  onMutate(result: TMutation): TMutationResult | null | undefined;
  mutationOptions?: MutationHookOptions<TMutation, TMutationVariables>;
}

export function usePromiseQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(query: DocumentNode) {
  const client = useApolloClient();
  return useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query: query,
        variables: variables,
      }),
    [client, query]
  );
}

export function useMediaXs() {
  return useMedia("(max-width: 575.98px)");
}

export function useMediaTablet() {
  return useMedia("(max-width: 770px)");
}

// default large is 1200px, but most popular is 1366px
export function useMediaLarge() {
  return useMedia("(min-width: 1300px)");
}

/** Clears graphql cache by name. TODO: replace with cache.evict built-in method */
export const evictCache =
  (...names: string[]) =>
  (cache: ApolloCache<any>) => {
    names.forEach((name) => cache.evict({ id: "ROOT_QUERY", fieldName: name }));
  };

export * from "./basic/basic";
export * from "./basic/changeLogs";
export * from "./basic/comments";
export * from "./basic/imports";
export * from "./basic/units";
export * from "./basic/users";
export * from "./basic/roles";
export * from "./basic/tenants";
export * from "./basic/countries";
export * from "./basic/locales";
export * from "./basic/localities";
export * from "./basic/positions";
export * from "./basic/employees";
export * from "./basic/employeeGroups";
export * from "./basic/machineCategories";
export * from "./basic/machineKinds";
export * from "./basic/machines";
export * from "./basic/infrastructures";
export * from "./basic/leaveTypes";
export * from "./basic/jobStatus";

export * from "./inventory/itemCategories";
export * from "./inventory/items";
export * from "./inventory/recipes";
export * from "./inventory/warehouses";
export * from "./inventory/paymentMethods";
export * from "./inventory/counterparties";
export * from "./inventory/transfers";
export * from "./inventory/adjustmentTypes";
export * from "./inventory/inventoryOrders";
export * from "./inventory/equipment";

export * from "./agro/ingredients";
export * from "./agro/crops";
export * from "./agro/cropVarieties";
export * from "./agro/diseases";
export * from "./agro/cropCycles";
export * from "./agro/cropFields";
export * from "./agro/cropFieldNotes";
export * from "./agro/cropFieldGroups";
export * from "./agro/activityCategories";
export * from "./agro/cropStages";
export * from "./agro/activities";
export * from "./agro/activityPayments";
export * from "./agro/workOrders";
export * from "./agro/inventoryRequests";
export * from "./agro/harvestEstimates";
export * from "./agro/plannings";
export * from "./agro/metrics";
export * from "./agro/waterSources";

export * from "./finance/accounts";
export * from "./finance/costCenters";
export * from "./finance/journalEntries";
export * from "./finance/financeOrders";
export * from "./finance/financeInvoices";
export * from "./finance/financePayments";
export * from "./finance/tokens";
export * from "./finance/fiscalYears";
export * from "./finance/financePeriods";
export * from "./finance/expenseCategories";
export * from "./finance/expenseItems";
export * from "./finance/taxComponents";
export * from "./finance/taxPlans";

export * from "./hr/salaryComponents";
export * from "./hr/salaryStructures";
export * from "./hr/payrollEntries";
export * from "./hr/leaveAssignments";
export * from "./hr/holidays";

export * from "./reports/stockReport";
export * from "./reports/journalReport";
export * from "./reports/generalLedgerReport";
export * from "./reports/inventoryEntryReport";
export * from "./reports/inventoryMovementReport";
export * from "./reports/activityReport";
export * from "./reports/ledgerReport";
export * from "./reports/trialBalanceReport";
export * from "./reports/tokenReport";
export * from "./reports/harvestReport";
export * from "./reports/prePayrollReport";
export * from "./reports/workOrderDailyReport";
export * from "./reports/expenseReport";
export * from "./reports/inputCostCenterReport";
export * from "./reports/incomeStatementReport";
export * from "./reports/balanceSheetReport";
