import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import {
  TenantsDocument,
  TenantsQuery,
  TenantsQueryVariables,
  TenantUpdateMutation,
  TenantUpdateMutationVariables,
  TenantUpdateDocument,
  TenantCreateMutation,
  TenantCreateMutationVariables,
  TenantCreateDocument,
  TenantDiscardMutation,
  TenantDiscardMutationVariables,
  TenantDiscardDocument,
  TenantQuery,
  TenantQueryVariables,
  TenantDocument,
  SortDirection,
  TenantStatsQuery,
  TenantStatsQueryVariables,
  TenantStatsDocument,
  TenantCloneDocument,
  TenantCloneMutation,
  TenantCloneMutationVariables,
  GenerateBillingPortalLinkMutation,
  GenerateBillingPortalLinkMutationVariables,
  GenerateBillingPortalLinkDocument,
} from "../../graphql";

export function useTenantOptions() {
  const [load, { loading, data }] = useLazyQuery<
    TenantsQuery,
    TenantsQueryVariables
  >(TenantsDocument, { variables: { sort: { name: SortDirection.Asc } } });

  const options =
    data?.tenants?.items?.map((t) => ({ key: t.id, label: t.name })) || [];

  return { load, loading, options };
}

export function useTenants(
  options?: QueryHookOptions<TenantsQuery, TenantsQueryVariables>
) {
  const { loading, data } = useQuery(TenantsDocument, options);

  if (!data || !data.tenants || !data.tenants.items) {
    return { loading };
  }

  const items = data.tenants.items;
  const totalCount = data.tenants.totalCount;

  return { loading, items, totalCount };
}

export function useTenantStats(
  options?: QueryHookOptions<TenantStatsQuery, TenantStatsQueryVariables>
) {
  const { loading, data } = useQuery(TenantStatsDocument, options);

  if (!data || !data.tenants || !data.tenants.items) {
    return { loading };
  }

  const items = data.tenants.items;
  const totalCount = data.tenants.totalCount;

  return { loading, items, totalCount };
}

export function useTenant({
  id,
  accountingSettings = false,
  withLocalities = true,
}: {
  id: string;
  accountingSettings?: boolean;
  withLocalities?: boolean;
}) {
  const { data } = useQuery<TenantQuery, TenantQueryVariables>(TenantDocument, {
    variables: { id, accountingSettings, withLocalities },
    skip: !id,
  });

  return data?.tenant;
}

export function useTenantUpdate(
  options?: MutationHookOptions<
    TenantUpdateMutation,
    TenantUpdateMutationVariables
  >
) {
  return useMutation(TenantUpdateDocument, options);
}

export function useTenantCreate(
  options?: MutationHookOptions<
    TenantCreateMutation,
    TenantCreateMutationVariables
  >
) {
  return useMutation(TenantCreateDocument, options);
}

export function useTenantDiscard(
  options?: MutationHookOptions<
    TenantDiscardMutation,
    TenantDiscardMutationVariables
  >
) {
  return useMutation(TenantDiscardDocument, options);
}

export function useTenantClone(
  options?: MutationHookOptions<
    TenantCloneMutation,
    TenantCloneMutationVariables
  >
) {
  return useMutation(TenantCloneDocument, options);
}

export function useGenerateBillingLink(
  options?: MutationHookOptions<
    GenerateBillingPortalLinkMutation,
    GenerateBillingPortalLinkMutationVariables
  >
) {
  return useMutation(GenerateBillingPortalLinkDocument, options);
}
