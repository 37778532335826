import { Button, Form as AntdForm } from "antd";
import {
  FormProps as AntdProps,
  Rule,
  FormInstance as AntdFormInstance,
  FormItemProps,
  FormListProps,
} from "antd/lib/form";
import { FormInstance } from "rc-field-form"; // use non-extended FormInstance
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { get, toPath } from "lodash";
import { useToggle } from "react-use";
import { useRouter } from "next/router";
import {
  useState,
  createContext,
  useContext,
  useEffect,
  ReactNode,
} from "react";
import { UserError } from "../../lib/graphql";
import { FieldData } from "rc-field-form/es/interface";
import { FormListFieldData } from "antd/lib/form/FormList";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Store } from "antd/lib/form/interface";
import { useCurrentLocale } from "../../lib/hooks";
import { shouldUpdate } from "../../lib/formats";

export type DeepNamePath = (string | number)[];
export type NamePath = string | number | DeepNamePath;
export type DeepFieldData = { name: DeepNamePath; value: any };

export interface FormProps<T>
  extends Omit<AntdProps, "initialValues" | "onFinish"> {
  initialValues?: T;
  onSubmit?(values: T, ctx: FormContextProps<T>): void;
  preventLeaving?: boolean;
  children?: React.ReactNode;
}

interface FormContextProps<T> {
  initialValues?: T;
  form: AntdFormInstance;
  submitting: boolean;
  setSubmitting(submitting: boolean): void;
  showSuccess(message: string): void;
  showError(message: string): void;
  showErrors(errors: Array<Pick<UserError, "message" | "path">>): void;
}

export function buildNamePath(name: NamePath, ...fields: DeepNamePath) {
  return [name].flat().concat(fields);
}

const FormContext = createContext<any>({});

export function useFormContext<T>(): FormContextProps<T> {
  return useContext(FormContext);
}

export function Form<T>({
  preventLeaving,
  initialValues,
  ...props
}: FormProps<T>) {
  const { showError, showErrors, showSuccess } = useCurrentLocale();
  const [submitting, setSubmitting] = useState(false);
  const [dirty, toggleDirty] = useToggle(false);
  const [form] = AntdForm.useForm(props.form);

  const ctx = {
    initialValues,
    submitting,
    setSubmitting,
    form,
    showSuccess,
    showError,
    showErrors: (errors: Array<Pick<UserError, "message" | "path">>) => {
      const errorFields: FieldData[] = [];
      const errorsToShow: Array<Pick<UserError, "message" | "path">> = [];

      errors.forEach((e) => {
        const name = toPath(e.path).map((p) =>
          isNaN(parseInt(p)) ? p : parseInt(p)
        );

        if (
          form.getFieldInstance(name) ||
          form.getFieldValue(name) != null ||
          form.getFieldValue(name + "Attributes")
        ) {
          errorFields.push({ name, errors: [e.message] });
        } else {
          errorsToShow.push(e);
        }
      });

      form.setFields(errorFields);
      showErrors(errorsToShow);
    },
  };

  return (
    <FormContext.Provider value={ctx}>
      {preventLeaving && <PreventLeave prevent={dirty && !submitting} />}
      <AntdForm
        form={form}
        initialValues={initialValues as Store}
        scrollToFirstError={{ behavior: "smooth", block: "center" }}
        onFieldsChange={() => toggleDirty(true)}
        onFinish={() => {
          if (submitting) return;
          setSubmitting(true);

          const values = form.getFieldsValue(true) as T;
          if (props.onSubmit) props.onSubmit(values, ctx);

          toggleDirty(false);
        }}
        {...props}
      />
    </FormContext.Provider>
  );
}

const messages = defineMessages({
  leavePrompt: {
    id: "form.confirmLeave",
    defaultMessage: "confirmLeave",
  },
});

function PreventLeave({ prevent }: { prevent: boolean }) {
  const router = useRouter();
  const intl = useIntl();

  useEffect(() => {
    const prompt = intl.formatMessage(messages.leavePrompt);

    const routeChangeStart = (url: any) => {
      if (router.asPath !== url && prevent) {
        router.events.emit("routeChangeError");
        if (!window.confirm(prompt)) {
          // Following is a hack-ish solution to abort a Next.js route change
          // as there's currently no official API to do so
          // See https://github.com/zeit/next.js/issues/2476#issuecomment-573460710
          // eslint-disable-next-line no-throw-literal
          throw `Route change to "${url}" was aborted (this error can be safely ignored). See https://github.com/zeit/next.js/issues/2476.`;
        }
      }
    };

    const beforeunload = (e: any) => {
      if (prevent) {
        e.preventDefault();
        return (e.returnValue = prompt);
      }
    };

    window.addEventListener("beforeunload", beforeunload);
    router.events.on("routeChangeStart", routeChangeStart);

    return () => {
      window.removeEventListener("beforeunload", beforeunload);
      router.events.off("routeChangeStart", routeChangeStart);
    };
  }, [router.asPath, router.events, prevent, intl]);

  return null;
}

Form.Item = ({ compact, ...props }: FormItemProps & { compact?: boolean }) => (
  <AntdForm.Item
    className={compact ? "form-item-compact" : undefined}
    {...props}
  />
);

function FormField<T>({
  name,
  children,
}: {
  name: NamePath;
  children: (field: T) => ReactNode;
}) {
  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate(name)}>
      {({ getFieldValue }) => children(getFieldValue(name))}
    </Form.Item>
  );
}

function FormList({
  renderItem,
  addText,
  onRemove,
  hideRemoveLabel,
  hideAdd,
  emptyText,
  ...props
}: Omit<FormListProps, "children"> & {
  renderItem: (item: FormListFieldData, removeIcon: ReactNode) => ReactNode;
  addText: ReactNode;
  onRemove?: (index: number, remove: () => void) => void;
  hideRemoveLabel?: boolean;
  hideAdd?: boolean | null;
  emptyText?: JSX.Element;
}) {
  const { form } = useFormContext();
  const [updateIndex, setUpdateIndex] = useState(0);
  const name = Array.isArray(props.name) ? props.name : [props.name];

  return (
    <AntdForm.List {...props}>
      {(items, { add, remove }) => (
        <>
          {items.length == 0 && emptyText}

          {items.map((item) => {
            const index = item.name;
            if (form.getFieldValue(name.concat(index, "_destroy"))) return;

            return renderItem(
              item,
              <Button
                type="dashed"
                danger
                icon={<MinusCircleOutlined />}
                block
                onClick={() => {
                  if (onRemove) {
                    onRemove(item.name, () => remove(index));
                  } else {
                    if (form.getFieldValue(name.concat(index, "id"))) {
                      form.setFields([
                        { name: name.concat(index, "_destroy"), value: true },
                      ]);
                      setUpdateIndex(updateIndex + 1);
                    } else {
                      remove(item.name);
                    }
                  }
                }}
              >
                {!hideRemoveLabel && (
                  <span>
                    <FormattedMessage id="remove" />
                  </span>
                )}
              </Button>
            );
          })}

          {!hideAdd && (
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
              style={{ marginTop: "6px" }}
            >
              <span>{addText}</span>
            </Button>
          )}
        </>
      )}
    </AntdForm.List>
  );
}

Form.Field = FormField;
Form.List = FormList;
Form.useForm = AntdForm.useForm;
Form.undestroyed = (v: any) => !get(v, "_destroy");

export const Rules: Record<
  | "required"
  | "minOneItem"
  | "gtZero"
  | "gtEqZero"
  | "percentage"
  | "email"
  | "onlyIntegers"
  | "onlyNumbers",
  Rule
> = {
  required: {
    required: true,
    message: (
      <FormattedMessage
        id="form.validation.presence"
        defaultMessage="invalid.presence"
      />
    ),
  },
  minOneItem: {
    min: 0,
    message: (
      <FormattedMessage
        id="form.validation.presence"
        defaultMessage="invalid.presence"
      />
    ),
  },
  gtZero: {
    required: true,
    type: "number",
    transform: (val: any) => parseFloat(val),
    min: 0.0000001,
    message: (
      <FormattedMessage id="form.validation.gtZero" defaultMessage="gtZero" />
    ),
  },
  gtEqZero: {
    required: true,
    type: "number",
    transform: (val: any) => parseFloat(val),
    min: 0,
    message: (
      <FormattedMessage
        id="form.validation.gtEqZero"
        defaultMessage="gtEqZero"
      />
    ),
  },
  percentage: {
    type: "number",
    transform: (val: any) => parseFloat(val),
    min: 0,
    max: 100,
    message: (
      <FormattedMessage
        id="form.validation.percentage"
        defaultMessage="percentage"
      />
    ),
  },
  email: {
    type: "email",
    message: (
      <FormattedMessage
        id="form.validation.email"
        defaultMessage="invalid.email"
      />
    ),
  },
  onlyIntegers: {
    type: "integer",
    message: (
      <FormattedMessage
        id="form.validation.integers"
        defaultMessage="integers"
      />
    ),
  },
  onlyNumbers: {
    type: "number",
    message: (
      <FormattedMessage id="form.validation.numbers" defaultMessage="numbers" />
    ),
  },
};

const transform = (val: any) => (val == null ? undefined : parseFloat(val));

export const RuleBuilder = {
  ltEq: (max?: number, type = "number") =>
    ({
      type,
      transform: type === "number" ? transform : undefined,
      max,
      message: (
        <FormattedMessage
          id="form.validation.ltEq"
          defaultMessage="ltEq"
          values={{ max }}
        />
      ),
    } as Rule),
  gtEq: (min?: number) =>
    ({
      type: "number",
      transform,
      min,
      message: (
        <FormattedMessage
          id="form.validation.gtEq"
          defaultMessage="gtEq"
          values={{ min }}
        />
      ),
    } as Rule),
  gt: (min?: number) =>
    ({
      type: "number",
      transform,
      min: Number(min || 0) + 1,
      message: (
        <FormattedMessage
          id="form.validation.gt"
          defaultMessage="gt"
          values={{ min }}
        />
      ),
    } as Rule),
  gtDate:
    ({ min, minField }: { min?: Date; minField?: string }) =>
    ({ getFieldValue }: { getFieldValue: (name: NamePath) => any }) => ({
      validator(_: any, value: any) {
        if (!value || (!minField && !min)) return Promise.resolve();

        const date = new Date(value);

        const minValue = minField
          ? getFieldValue(minField).toDate()
          : min
          ? min
          : undefined;

        if (minValue < date) return Promise.resolve();

        return Promise.reject(
          <FormattedMessage
            id="form.validation.gt"
            defaultMessage="gt"
            values={{ min: minValue.toString() }}
          />
        );
      },
    }),
  custom:
    (validator: (form: FormInstance, value: any) => true | ReactNode) =>
    (form: FormInstance) => ({
      validator(_: any, value: any) {
        const result = validator(form, value);
        if (result === true) return Promise.resolve();

        return Promise.reject(result);
      },
    }),
};
