import PlanningIcon from "../public/images/menu/planning.svg";
import AgricultureIcon from "../public/images/menu/agriculture.svg";
import InventoryIcon from "../public/images/menu/inventory.svg";
import MachineryIcon from "../public/images/menu/machinery.svg";
import ReportsIcon from "../public/images/menu/reports.svg";
import DashboardIcon from "../public/images/menu/dashboard.svg";
import ActiveCalendar from "../public/images/icons/calendar-active.svg";
import PendingCalendar from "../public/images/icons/calendar-pending.svg";
import ExpiredCalendar from "../public/images/icons/calendar-expired.svg";
export { default as ExternalLinkIcon } from "../public/images/icons/external-link.svg";
export { default as AttachIcon } from "../public/images/icons/attach.svg";
import MapOutline from "../public/images/menu/map-outline.svg";
import {
  DollarCircleOutlined,
  SettingOutlined,
  TeamOutlined,
} from "@ant-design/icons";
// https://icomoon.io
export { default as CloudRainIcon } from "../public/images/icons/cloud-rain.svg";
export { default as RaindropIcon } from "../public/images/icons/raindrop.svg";
export { default as SunIcon } from "../public/images/icons/sun.svg";
export { default as WindIcon } from "../public/images/icons/wind.svg";
export { default as Briefcase } from "../public/images/icons/briefcase.svg";
export { default as Tractor } from "../public/images/icons/tractor.svg";

export const MenuIcons = {
  PlanningIcon,
  AgricultureIcon,
  InventoryIcon,
  InfrastructureIcon: SettingOutlined,
  MachineryIcon,
  HrIcon: TeamOutlined,
  FinanceIcon: DollarCircleOutlined,
  ReportsIcon,
  DashboardIcon,
  MapOutline,
};

export const CalendarIcons = {
  ActiveCalendar,
  PendingCalendar,
  ExpiredCalendar,
};
